import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import RenderComponents from "../components/RenderComponents"
import Stack from "../sdk/contentstack"
import init from "../modules/realtime"

const Home = props => {
  let [data, setData] = useState({ contentstackPage: null, contentstackHeader: null, contentstackFooter: null })

  if (process.env.environment !== 'instant-preview') {
    data = props.data;
  }
  else {
    useEffect(async () => {
      const [indexPage, header, footer] = await Promise.all([
        Stack.getEntryByUrl("page", location.pathname, ["page_components.from_blog.featured_blogs",]),
        Stack.getEntry("header", "navigation_menu.page_reference"),
        Stack.getEntry("footer")
      ])

      setData({ contentstackPage: indexPage[0], contentstackHeader: header[0], contentstackFooter: footer[0] })
      init(indexPage[0].uid)
    }, [])
  }

  return (
    <Layout data={data}>
      <SEO title="Home" />
      {data.contentstackPage && data.contentstackPage.page_components ? (
        <RenderComponents components={data.contentstackPage.page_components} />
      ) : (
        ""
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    contentstackPage(title: { eq: "Home" }) {
      title
      url
      uid
      seo {
        enable_search_indexing
        keywords
        meta_description
        meta_title
      }
      page_components {
        contact_details {
          address
          email
          phone
        }
        from_blog {
          title_h2
          featured_blogs {
            title
            uid
            url
            featured_image {
              title
              url
            }
            body
            author {
              title
              uid
              bio
            }
          }
          view_articles {
            title
            href
          }
        }
        hero_banner {
          banner_description
          banner_title
          bg_color
          banner_image {
            title
            url
          }
          call_to_action {
            title
            href
          }
        }
        our_team {
          title_h2
          description
          employees {
            name
            designation
            image {
              title
              url
            }
          }
        }
        section {
          title_h2
          description
          image {
            title
            url
          }
          image_alignment
          call_to_action {
            title
            href
          }
        }
        section_with_buckets {
          title_h2
          description
          buckets {
            title_h3
            description
            icon {
              title
              url
            }
            call_to_action {
              title
              href
            }
          }
        }
        section_with_cards {
          cards {
            title_h3
            description
            call_to_action {
              title
              href
            }
          }
        }
      }
    }
  }
`

export default Home
